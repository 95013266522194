/* body {
    font-family: 'Lato', sans-serif !important;
    font-weight: 400;
} */
body {
    margin: 0;
    padding: 0;
    font-size: 0.7rem;
    font-family: 'Roboto', sans-serif;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
.opacity5 {
    opacity: 0.5;
}
.opacity8 {
    opacity: 0.8;
}
.strong {
    font-weight: 700;
}
.pt-6 {
    padding-top: 2rem;
}
.pt-8 {
    padding-top: 4rem;
}
.pb-6 {
    padding-bottom: 2rem;
}
.pb-8 {
    padding-bottom: 4rem;
}
.mt-6 {
    margin-top: 2rem;
}
.mt-7 {
    margin-top: 3rem;
}
.mt-8 {
    margin-top: 4rem;
}
.mb-6 {
    margin-bottom: 2rem;
}
.mb-7 {
    margin-bottom: 3rem;
}
.mb-8 {
    margin-bottom: 4rem;
}
.no-gutter-center {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.btn-primary {
    background: #2d86c0;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.mediaAvatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.flex-right {
    display: flex;
    justify-content: flex-end;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sticky {
    z-index: 50;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
p {
    text-align: justify;
}
ul {
    padding-left: 18px;
}
/* li {
    list-style-image: url('https://f0.pngfuel.com/png/223/527/check-mark-computer-icons-green-check-mark-2-icon-check-mark-png-clip-art-thumbnail.png');
} */

.modal-header button span {
    display: none;
}
